import React, { useState, useRef, useEffect } from 'react';
import { Modal, Input, Button, message } from 'antd';
import { UserOutlined, RobotOutlined, SendOutlined, AudioOutlined, StopOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

function NotesChat({ msg, setChat, activeTab }) {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('AIzaSyAE1z7awgVAVbbHQc7jROz27mDepwePZwI');
  const [isListening, setIsListening] = useState(false);
  const [isVoiceInput, setIsVoiceInput] = useState(false);
  const isVoiceInputRef = useRef(isVoiceInput);
  const messagesEndRef = useRef(null);
  const [abortController, setAbortController] = useState(null);
  const recognitionRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    isVoiceInputRef.current = isVoiceInput;
  }, [isVoiceInput]);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = false;
      recognitionRef.current.lang = 'en-US';
      
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log("voice", "setting true");
        setIsVoiceInput(true); // Update state
        isVoiceInputRef.current = true; // Update ref immediately
        console.log("voice t", isVoiceInputRef.current); // Log the ref value
        handleSendMessage(transcript); // Pass the transcript to handleSendMessage
        setIsListening(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
      };
    } else {
      console.warn('Speech recognition not supported in this browser.');
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  const startListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      setIsListening(true);
      setIsVoiceInput((prev) => true); // Functional update to ensure immediate state change
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);

  // // Set a natural voice
  // const voices = window.speechSynthesis.getVoices();
  // const naturalVoice = voices.find((voice) => 
  //   voice.name.includes('Google') || voice.name.includes('Natural') || voice.lang === 'en-US'
  // );
  // if (naturalVoice) {
  //   utterance.voice = naturalVoice;
  // }

  // // Adjust pitch and rate for natural sound
  // utterance.pitch = 1; // Range: 0 to 2 (1 is default)
  // utterance.rate = 1; // Range: 0.1 to 10 (1 is default)

  // // Add pauses between sentences
  // utterance.text = text.replace(/\./g, '. '); // Add space after periods
    window.speechSynthesis.speak(utterance);
  };

  const formatMessagesForAPI = (messages) => {
    return messages.map(message => ({
      parts: [{ text: message.content }],
      role: message.role === 'user' ? 'user' : 'model'
    }));
  };
  const streamMessage = async (content, key, isVoiceInput, onProgress, abortSignal) => {
    const conversationHistory = formatMessagesForAPI([...messages, { role: 'user', content }]);

    try {
      const response = await fetch(
        `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${key}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            system_instruction: {
              parts: { text: msg },
            },
            contents: conversationHistory,
            generationConfig: {
              temperature: 0,
              maxOutputTokens: 800,
            },
          }),
          signal: abortSignal,
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const text = data?.candidates?.[0]?.content?.parts?.[0]?.text || '';
  
      if (!text) {
        throw new Error('No text in response');
      }
  
      const words = text.split(' ');
      let fullText = '';
  
      for (const word of words) {
        if (abortSignal?.aborted) {
          throw new Error('Stream aborted by user');
        }
        fullText += `${word} `;
        onProgress(fullText);
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
  
      console.log("voice", isVoiceInput);
      // Use the isVoiceInput parameter directly
      if (isVoiceInput) {
        speakText(fullText);
      }
    } catch (error) {
      if (abortSignal?.aborted) {
        console.log('Stream aborted by user.');
      } else {
        console.error('Error streaming message:', error);
        throw error;
      }
    }
  };
  const handleStopStream = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setIsLoading(false);
    }
  };
  const handleSendMessage = async (content) => {
    const userMessage = { role: 'user', content };
    const assistantMessage = {
      role: 'assistant',
      content: '',
      isStreaming: true,
    };
  
    setMessages((prev) => [...prev, userMessage, assistantMessage]);
    setIsLoading(true);
    const controller = new AbortController();
    setAbortController(controller);
  
    try {
      // Pass the ref value (isVoiceInputRef.current) to streamMessage
      await streamMessage(content, apiKey, isVoiceInputRef.current, (progress) => {
        setMessages((prev) => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1] = {
            role: 'assistant',
            content: progress,
            isStreaming: true,
          };
          return newMessages;
        });
      }, controller.signal);
  
      setMessages((prev) => {
        const newMessages = [...prev];
        newMessages[newMessages.length - 1] = {
          role: 'assistant',
          content: newMessages[newMessages.length - 1].content,
          isStreaming: false,
        };
        return newMessages;
      });
    } catch (error) {
      if (controller.signal.aborted) {
        console.log('Stream stopped by the user.');
      } else {
        console.error('Error:', error);
        message.error('Failed Ai Model is currently down');
        setMessages((prev) => prev.slice(0, -1));
      }
    } finally {
      setIsLoading(false);
      setAbortController(null);
      setIsVoiceInput(false); // Reset state
      isVoiceInputRef.current = false; // Reset ref
    }
  };
  function getName(tab) {
    switch (tab) {
      case "history":
        return "HNP Notes";
      case "progress":
        return "Progress Notes";
      case "consultation":
        return "Consultations Notes";
      case "treatment":
        return "Treatment Notes";
      case "goals":
        return "Goals Notes";
      case "physicalTherapy":
        return "Physical Therapy Notes";
      case "rehabilitation":
        return "Rehabilitation Therapy Notes";
      case "occupational":
        return "Occupational Therapy Notes";
      case "speech":
        return "Speech Therapy Notes";
      default:
        return "History";
    }
  }

  const ChatMessage = ({ message }) => {
    const isUser = message.role === 'user';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: isUser ? 'row-reverse' : 'row',
          alignItems: 'flex-start',
          gap: '1rem',
          marginBottom: '1rem',
        }}
      >
        <div
          style={{
            padding: '0.75rem',
            borderRadius: '50%',
            backgroundColor: isUser ? '#4299e1' : '#718096',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {isUser ? (
            <UserOutlined style={{ color: 'white', fontSize: '1.25rem' }} />
          ) : (
            <RobotOutlined style={{ color: 'white', fontSize: '1.25rem' }} />
          )}
        </div>
        <div
          style={{
            flex: 1,
            padding: '1rem',
            borderRadius: '1rem',
            backgroundColor: isUser ? '#ebf8ff' : '#f7fafc',
            position: 'relative',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            maxWidth: '70%',
          }}
        >
          <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap', margin: 0 }}>
            {message.content}
            {message.isStreaming && (
              <span
                style={{
                  display: 'inline-block',
                  width: '0.5rem',
                  height: '1rem',
                  marginLeft: '0.25rem',
                  backgroundColor: '#718096',
                  animation: 'pulse 1.5s infinite',
                }}
              />
            )}
          </p>
          {!isUser && !message.isStreaming && (
            <Button
              type="text"
              icon={<AudioOutlined />}
              onClick={() => speakText(message.content)}
              style={{
                position: 'absolute',
                bottom: '0.5rem',
                right: '0.5rem',
                padding: '0.25rem',
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const ChatInput = ({ onSendMessage, disabled }) => {
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
      e.preventDefault();
      if (message.trim() && !disabled) {
        setIsVoiceInput(false);
        onSendMessage(message);
        setMessage('');
      }
    };

    return (
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          gap: '0.5rem',
          padding: '1rem',
          backgroundColor: '#f7fafc',
          borderRadius: '0.5rem',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your question..."
          disabled={disabled}
          style={{ flex: 1, borderRadius: '0.5rem', padding: '0.75rem' }}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled || !message.trim()}
          icon={<SendOutlined />}
          style={{ borderRadius: '0.5rem', padding: '0.75rem' }}
        />
        <Button
          type="default"
          icon={<AudioOutlined />}
          onClick={isListening ? stopListening : startListening}
          disabled={disabled}
          style={{ borderRadius: '0.5rem', padding: '0.75rem' }}
        >
          {isListening ? 'Stop Listening' : 'Voice Input'}
        </Button>
        {disabled && (
          <Button
            type="danger"
            icon={<StopOutlined />}
            onClick={handleStopStream}
            style={{ borderRadius: '0.5rem', padding: '0.75rem' }}
          >
            Stop
          </Button>
        )}
      </form>
    );
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
        zIndex: 100,
      }}
    >
      <ResizableBox
        width={500}
        height={500}
        minConstraints={[300, 200]}
        maxConstraints={[800, 600]}
        resizeHandles={['nw', 'ne', 'sw', 'se']}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              background: 'linear-gradient(135deg, #4299e1, #3182ce)',
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <h1 style={{ color: 'white', margin: 0, fontSize: '1.25rem', fontWeight: '600' }}>
              AI Chat ({getName(activeTab)})
            </h1>
            <CloseOutlined
              onClick={() => setChat(null)}
              style={{ color: 'white', cursor: 'pointer', fontSize: '1.25rem' }}
            />
          </div>

          <div
            style={{
              flex: 1,
              overflowY: 'auto',
              padding: '1rem',
              backgroundColor: '#f7fafc',
            }}
          >
            {messages.map((message, index) => (
              <ChatMessage key={index} message={message} />
            ))}
            <div ref={messagesEndRef} />
          </div>

          <div style={{ padding: '1rem', borderTop: '1px solid #e2e8f0' }}>
            <ChatInput onSendMessage={handleSendMessage} disabled={isLoading} />
          </div>
        </div>
      </ResizableBox>
    </div>
  );
}

export default NotesChat;