import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  apiGetOrders,
  apihitCall,
  loginService,
  // registerService,
  // verifyOtp,
} from "../../service/authService";
import { APIHitCall } from "../case-manager/caseManagerService";
import { useSelector } from "react-redux";
// import { setTempToken } from "./authActions";

const initialState = {
  isAuth: false,
  user: {},
  tempToken: null,
  token: "",
  fcmToken: '',
  loading: false,
  enterOtp: false,
  newUser: {},
  signModal: false,
  loginModal: false,
  forgotPasswordModal: false,
  errorMessage: undefined,
  service: {
    loading: false,
    data: [],
    isSuccess: false,
  },
  subService: {
    loading: false,
    data: {},
  },
  addServiceFormData: {},
  allPatients: {
    loading: false,
    data: [],
  },
  patientShippingAddress: [],
  ordersList: [],
  patientDetail: {},
  insuranceDetails: [],
  patientInsurance: [],
  patientAppointmentlist: {},
  patientPastAppointmentlist: {},
  isDemoModal: false,
};

export const loginAsync = createAsyncThunk(
  "login",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    Object.keys(payload).forEach((i) => {
      formData.append(i, payload[i]);
    });
    formData.append("action", "loginNew");
    formData.append("country", "US");
    formData.append("deviceType", "1");
    formData.append("deviceId", "web-123");
    const response = await loginService(formData, user?.token);
    return response.data;
  }
);

// export const signupAsync = createAsyncThunk(
//   "signup",
//   async (payload, { getState }) => {
//     const { user } = getState();
//     const formData = new FormData();
//     Object.keys(payload).forEach((i) => {
//       formData.append(i, payload[i]);
//     });
//     formData.append("action", "registerNew");
//     formData.append("city", "Fremont");
//     formData.append("state", "California");
//     formData.append("deviceId", "web-123");
//     const response = await registerService(formData, user?.token);
//     return response.data;
//   }
// );

// export const verifyOtpAsync = createAsyncThunk(
//   "verifyOtp",
//   async (payload, { getState }) => {
//     const { user } = getState();
//     const formData = new FormData();
//     Object.keys(payload).forEach((i) => {
//       formData.append(i, payload[i]);
//     });
//     formData.append("action", "submitOTPKE");
//     formData.append("country", "usa");
//     formData.append("userId", user?.newUser?.id);
//     const response = await verifyOtp(formData, user?.token);
//     return response.data;
//   }
// );

export const getServiceAction = createAsyncThunk(
  "getService",
  async (payload, { getState }) => {
    try {
      // const { user } = getState();
      const { cityId, zipcode, token } = payload;
      const formData = new FormData();
      formData.append("action", "city_category_2");
      formData.append("cityId", cityId || "Fremont");
      // formData.append("auterizetokenid", "Fremont");
      // let zipCode = JSON.parse(localStorage.getItem("__location"));

      // if (zipCode !== null) {
      //   zipCode = JSON.parse(localStorage.getItem("__location")).value
      //     .structured_formatting.main_text;
      //   if (!isNaN(parseInt(zipCode))) {
      //     zipCode = parseInt(zipCode);
      //   }
      // }

      // const fremontZipCodes = [94536, 94538, 94539, 94555, 94560];

      formData.append("zipcode", zipcode ? zipcode : "");
      // formData.append("lat", "37.5485396");
      // formData.append("lng", "-121.988583");
      const response = await apihitCall(formData, token);

      return response?.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getSubServiceAction = createAsyncThunk(
  "getSubService",
  async (payload, { getState }) => {
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "all_subcategory_by_city");
    formData.append("category_id", payload);
    formData.append("city_id", "Baltimore");
    formData.append("lat", "37.5485396");
    formData.append("lng", "-121.988583");
    const response = await apihitCall(formData, user?.token);
    return response?.data;
  }
);

export const getShippingAddressAction = createAsyncThunk(
  "getShippingAddress",
  async (payload, { getState }) => {
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "get_society_user_address");
    formData.append("society_id", user?.user?.society_id);
    formData.append("userId", payload);
    formData.append("auterizetokenid", user?.user.id);
    const response = await apihitCall(formData, user?.token);
    return response?.data;
  }
);

export const placeOrderAction = createAsyncThunk(
  "placeOrder",
  async (payload, { getState }) => {
    const { user, caseManagerSlice } = getState();
    const formData = new FormData();
    Object.keys(payload).forEach((i) => {
      formData.append(i, payload[i]);
    });
    const userName = caseManagerSlice?.patient?.data?.filter(
      (i) => i.patientid === user?.addServiceFormData?.user_id
    )?.[0];
    formData.append("action", "new_payment");
    formData.append("casemanager", user?.id);
    formData.append("name", userName.f_name);
    formData.append("email", userName.email);
    formData.append("phone", userName?.phone);

    const response = await apihitCall(formData, user?.token);
    return response?.data;
  }
);

export const getPlaceOrderAction = createAsyncThunk(
  "getPlaceOrder",
  async (_, { getState }) => {
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "get_order_all");
    formData.append("page_start", 0);
    formData.append("page_end", 50);
    formData.append("country", "US");
    formData.append("userId", user?.id);
    formData.append("email", user?.email);
    formData.append("phone", user?.phone);
    const response = await apihitCall(formData, user?.token);
    return response?.data;
  }
);

export const addPatientAddressAction = createAsyncThunk(
  "addPatientAddress",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    Object.keys(payload).forEach((i) => {
      formData.append(i, payload[i]);
    });
    formData.append("action", "add_user_shipping");
    formData.append("auterizetokenid", user?.user.id);
    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);

export const addPatientinsuracneAction = createAsyncThunk(
  "addPatientinsuracneAction",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    Object.keys(payload).forEach((i) => {
      formData.append(i, payload[i]);
    });
    formData.append("action", "add_insurance_details");
    formData.append("auterizetokenid", user?.user.id);
    formData.append("clinicId", user?.user?.society_id ?? "");

    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);

export const getPatientinsuracneAction = createAsyncThunk(
  "getPatientinsuracneAction",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("userId", payload);
    formData.append("familymemberId", "");
    formData.append("action", "get_user_insurance_all");
    formData.append("auterizetokenid", user?.user.id);
    formData.append("clinicId", user?.user.society_id);

    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);

export const getPatientDetail = createAsyncThunk(
  "getPatientDetail",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "user_detail");
    formData.append("country", "US");
    formData.append("id", payload);
    formData.append("auterizetokenid", user?.user.id);
    formData.append("society_id", user?.user?.society_id ?? "");

    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);



export const getPatientAppointments = createAsyncThunk(
  "getPatientAppointments",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    // formData.append("action", "inprocessTaskcasemanager");
    formData.append("auterizetokenid", user?.user.id);
    formData.append("country", "US");
    formData.append("casemanager", user?.user?.id);
    formData.append("auterizetokenid", user?.user?.id);
    formData.append("patientId", payload);
    formData.append("page_start", 0);
    formData.append("jobstatus", 0);
    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);

export const getPastPatientAppointments = createAsyncThunk(
  "getPastPatientAppointments",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    // formData.append("action", "inprocessTaskcasemanager");
    formData.append("auterizetokenid", user?.user?.id);
    formData.append("country", "US");
    formData.append("casemanager", user?.user?.id);
    formData.append("patientId", payload);
    formData.append("page_start", 0);
    formData.append("jobstatus", 1);
    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);

export const getTimeSlotServiceAction = createAsyncThunk(
  "getTimeSlotServiceAction",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "get_timeslot_byservice");
    formData.append("serviceId", 0);
    const response = await apihitCall(formData, user?.token);
    return response.data;
  }
);
    
export const logoutapi = createAsyncThunk(
  "logoutapi",
  async (payload, { getState }) => {
    
    console.log("User logged out starting");
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "userLogout");
    formData.append("country", "US");
    formData.append("id", user?.user.id);
    formData.append("auterizetokenid", user?.user.id);
    console.log("token", user?.token)
    console.log(payload)
    let response = await apihitCall(formData,  user?.token);
        console.log("User logged out")
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    logout: (state) => {
      
      state.loginModal = false;
      state.isAuth = false;
      state.user = null;
      state.token = null;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.loginModal = false;
      state.token = action.payload.token;
      state.isAuth = action.payload.isAuth;
    },
    setTempToken: (state, action) => {
      state.tempToken = action.payload;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    updateSignModal: (state, action) => {
      state.signModal = action.payload;
    },
    updateLoginModal: (state, action) => {
      state.loginModal = action.payload;
    },
    addServiceFormDataAction: (state, action) => {
      state.addServiceFormData = {
        ...state.addServiceFormData,
        ...action.payload,
        payment_status: "insurance",
      };
    },
   
    updateForgotPasswordModal: (state, action) => {
      state.forgotPasswordModal = action.payload;
    },
    toggleDemoModal: (state, action) => {
      state.isDemoModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // .addCase(signupAsync.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(signupAsync.fulfilled, (state, action) => {
      //   if (action.payload?.status === 200) {
      //     state.enterOtp = true;
      //     state.newUser = action.payload.result?.[0];
      //     state.signModal = true;
      //   } else {
      //     Modal.error({
      //       title: action.payload?.message,
      //     });
      //   }
      // })
      // .addCase(verifyOtpAsync.pending, (state, action) => {
      //   state.loading = true;
      // })
      // .addCase(verifyOtpAsync.fulfilled, (state, action) => {
      //   state.isAuth = true;
      // })
      .addCase(loginAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        if (action?.payload?.status === 200) {
          state.isAuth = true;
          state.user = action?.payload?.result?.[0] ?? {};
        } else {
          state.errorMessage = action?.payload?.message;
        }
        state.loading = false;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getServiceAction.pending, (state, action) => {
        state.service.loading = true;
      })
      .addCase(getServiceAction.fulfilled, (state, action) => {
        state.service.loading = false;
        state.service.isSuccess = true;
        state.service.data = action.payload?.topcategories ?? [];
      })
      .addCase(getSubServiceAction.fulfilled, (state, action) => {
        const res = {
          ...state.subService.data,
          [action.meta.arg]: action.payload.result,
        };
        state.subService.data = res;
      })
      .addCase(getShippingAddressAction.fulfilled, (state, action) => {
        state.patientShippingAddress = action.payload.result;
      })
      .addCase(getPlaceOrderAction.fulfilled, (state, action) => {})
      .addCase(addPatientAddressAction.fulfilled, (state, action) => {
        // state.addServiceFormData.usershipping_id = action.payload.result;
      })
      .addCase(addPatientinsuracneAction.fulfilled, (state, action) => {})
      .addCase(getPatientinsuracneAction.fulfilled, (state, action) => {
        const [response] = action.payload.result;
        state.patientInsurance = [...state.patientInsurance, response];
      })
      .addCase(getPatientDetail.fulfilled, (state, action) => {
        const [response] = action.payload.result;
        state.patientDetail = {
          ...state.patientDetail,
          [response?.user_id]: response,
        };
        state.insuranceDetails = response.insurancedetails;
      })
      .addCase(getPatientAppointments.fulfilled, (state, action) => {
        state.patientAppointmentlist = {
          ...state.patientAppointmentlist,
          [action.meta?.arg]: action.payload?.result,
        };
      })
      .addCase(getPastPatientAppointments.fulfilled, (state, action) => {
        state.patientPastAppointmentlist = {
          ...state.patientPastAppointmentlist,
          [action.meta?.arg]: action.payload?.result,
        };
      })
      // .addCase(setTempToken.fulfilled, (state, action) => {
      //   state.tempToken = action.payload ?? null;
      // });
  },
});

export const getOrders = createAsyncThunk(
  "getOrders",
  async (payload, { getState }) => {
    const { user } = getState();
    const formData = new FormData();
    // Object.keys(payload).forEach((i) => {
    //     formData.append(i, payload[i]);
    // });
    formData.append("auterizetokenid", user.user.id);
    formData.append("action", "get_order_all");
    formData.append(
      "userId",
      user?.newUser?.id !== undefined ? user?.newUser?.id : user?.user.id
    );
    const response = await apiGetOrders(formData, user?.token);
    return response.data;
  }
);

export const {
  
  updateLoginModal,
  updateSignModal,
  updateForgotPasswordModal,
  logout,
  addServiceFormDataAction,
  setUserData,
  setToken,
  setTempToken,
  setFcmToken,
  toggleDemoModal,
  
} = userSlice.actions;

export default userSlice.reducer;
