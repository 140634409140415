import React, { useEffect, useState } from 'react'
import { authStep1 } from '../features/auth/authService';
import { apihitCall } from '../service/authService';
import { Notify } from '../blocks/dialogs/Notify';
import { Form, Modal, Spin } from 'antd';
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch, useSelector } from 'react-redux';
import { apiHitWithTempToken } from '../service/authService';
import { getClient_id, getClientSecret } from '../utils/functions';
import { setTempToken } from '../features/auth/authSlice';
import axiosHIT from 'axios';

function ForgotPasswordModal({ isModalOpen, handleCancel }) {

    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const formData = new FormData();
    const [loader, setLoader] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [mail, setMail] = useState("");
    const [OTP, setOTP] = useState("");
    const [form] = Form.useForm();
    const form2 = form;

      useEffect(() => {
          generateTempToken();
      }, [])
    const generateTempToken = async () => {
        try {
            const res = await authStep1();
            console.log('token temp response', res);
            if (res.status === 200) {
                dispatch(setTempToken(res?.data?.access_token));
            }
        } catch (error) {}
    };

    const authStep1 = (code) => {
        const formdata = new FormData();
        formdata.append("client_id", getClient_id());
        formdata.append("client_secret", getClientSecret());
        formdata.append("grant_type", code ? 'authorization_code' : "client_credentials");
        if(code) {
            formdata.append("code", code);
        }
    
        return axiosHIT({
            url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
            method: "post",
            data: formdata,
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            },
        });
    };


    const closeModal = () => {
        setModalOpen(false);
    };


    
    const onFinishForm = async (data) => {
        setMail(data.mail);
        setLoader(true);
        const resdata = await authStep1();
        if (resdata?.data?.access_token) {
            const formData = new FormData();
            formData.append("action", "forgot_password_updated");
            formData.append("email", data?.mail);
            let res = await apiHitWithTempToken(formData);
           // let res = await apihitCall(formData, resdata?.data?.access_token);
            if (res?.data?.status == 200) {
                setLoader(false);
                Notify('success', res.data.message);
                handleCancel();
            } else {
                setLoader(false);
                Notify('error', res.data.message);
            }
        } else {
            setLoader(false);
            Notify('error', "opps somethig went wrong");
        }

    };

    const otpVarify = async () => {

        if (!OTP || OTP.toString().length < 6) {
            Notify("error", "Please fill otp");
            return;
        }

        setLoader(true);
        const resdata = await authStep1();
        const formData = new FormData();
        formData.append("action", "verify_login_otp");
        formData.append("otp", OTP);
        formData.append("userId", user?.id);
        let res = await apihitCall(formData, resdata?.data?.access_token);
        if (res?.data?.status == 200) {
            setLoader(false);
            Notify('success', res.data.message);
            setModalOpen(true);
        } else {
            setLoader(false);
            Notify('error', res.data.message);
        }

    }

    const resendOtp = async () => {
        setLoader(true);
        const resdata = await authStep1();
        const formData = new FormData();
        formData.append("action", "resend_login_passcode");
        formData.append("userId", user?.id);
        let res = await apihitCall(formData, resdata?.data?.access_token);
        if (res?.data?.status == 200) {
            setLoader(false);
            Notify('success', res.data.message);
            setModalOpen(true);
        } else {
            setLoader(false);
            Notify('error', res.data.message);
        }
    }

    return (
        <>
            <Modal
                title="Forgot Password"
                footer={null}
                open={isModalOpen}
                onCancel={handleCancel}
            >
                <Form onFinish={onFinishForm} form={form2} style={{ width: "100%" }}>
                    <div className="no-border p-3">
                        <div className="form-field">
                            <Form.Item
                                name="mail"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter valid email",
                                    },
                                ]}
                            >
                                <input
                                    style={{ width: "100%" }}
                                    type="email"
                                    placeholder="Enter Your Mail"
                                    required
                                />
                            </Form.Item>
                            <div className="slots-button mt-3">

                                {loader ? (
                                    <Spin />
                                ) : (
                                    <>
                                        <button className="btn btn-secondary">Cancel</button>
                                        <button className="btn btn-primary ml-1">Forgot Password</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>

            {/* <Modal
                title="Varify Otp"
                footer={null}
                open={modalOpen}
                onCancel={closeModal}
            >
                <div className=' p-2'>
                    <p>Passcode has been sent to {mail}</p>
                    <div className='p-4'>
                        <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} />
                    </div>
                    <div>
                        <ResendOTP className="resend-otp" onResendClick={(otp) => resendOtp(otp)} />
                    </div>
                    {loader ? (
                        <Spin />
                    ) : (
                        <>
                            <button className="btn btn-primary mt-4" onClick={otpVarify}>Continue</button>
                        </>
                    )}
                </div>
            </Modal> */}
        </>
    )
}

export default ForgotPasswordModal;